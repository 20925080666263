/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bandaid-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2.68 7.676l6.49-6.504a4 4 0 015.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 01-5.653-5.66l.001-.002 1.505-1.492.001-.002zm5.71-2.858a.5.5 0 10-.708.707.5.5 0 00.707-.707zM6.974 6.939a.5.5 0 10-.707-.707.5.5 0 00.707.707M5.56 8.354a.5.5 0 10-.707-.708.5.5 0 00.707.708m2.828 2.828a.5.5 0 10-.707-.707.5.5 0 00.707.707m1.414-2.121a.5.5 0 10-.707.707.5.5 0 00.707-.707m1.414-.707a.5.5 0 10-.706-.708.5.5 0 00.707.708zm-4.242.707a.5.5 0 10-.707.707.5.5 0 00.707-.707m1.414-.707a.5.5 0 10-.707-.708.5.5 0 00.707.708m1.414-2.122a.5.5 0 10-.707.707.5.5 0 00.707-.707M8.646 3.354l4 4 .708-.708-4-4zm-1.292 9.292l-4-4-.708.708 4 4z"/>',
    },
});
